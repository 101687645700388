export enum REFINEMENT_DIMENSION {
    ATTRIBUTE = 'attribute',
    ATTRIBUTE_PLURAL = 'attributes',
    CATEGORY = 'category',
    CATEGORY_PLURAL = 'categories',
    TEMPLATE_USE_CASE = 'templateUseCase',
    TEMPLATE_USE_CASES = 'templateUseCases',
    KEYWORD = 'keyword',
    COLLECTION = 'collection',
}

export enum FILTER_TYPE {
    CHECKBOX = 'checkbox',
    RADIO = 'radio',
    COLOR = 'color',
    FLAT_TAXONOMY = 'flattaxonomy',
    FACETED_TAXONOMY = 'facetedtaxonomy',
    FLAT_TEMPLATE_USE_CASE = 'flattemplateusecase',
    FACETED_TEMPLATE_USE_CASE = 'facetedtemplateusecase',
    UNKNOWN = 'unknown',
}

export enum QUICKVIEW_FILTER_TYPE {
    RADIO = 'radio',
    ATTRIBUTE = 'attribute',
    TEMPLATE_USE_CASE = 'templateUseCase',
}

export enum DESIGN_VARIATION_ATTRIBUTE {
    ImagePlaceholder = 'imageplaceholder',
    NumberOfPhotos = 'numberofphotos',
    DesignColor = 'designcolor',
}

export enum LOGGER_LEVEL {
    DEBUG = 0,
    INFO = 1,
    WARNING = 2,
    ERROR = 3,
    CRITICAL = 4,
}

export enum AnalyticsNames {
    DesignTile = 'DesignTile',
    Quickview = 'Quickview',
    FavoritesTile = 'FavoritesTile',
    PhotoPersonalization = 'PhotoPersonalization',
}

export enum ANALYTICS_EVENT_ACTIONS {
    SELECT_COLOR_SWATCH = 'SelectColorSwatch',
    OPEN_QUICKVIEW = 'OpenQuickview',
    GALLERY_DESIGN_ENGAGEMENT = 'Gallery Design Engagement',
    GALLERY_UPSELL_ENGAGEMENT = 'Gallery Upsell Engagement',
    FAVORITES_DESIGN_ENGAGEMENT = 'Favorites Design Engagement',
    FLY_OUT_CLICKED = 'Fly-Out Clicked',
    FLY_OUT_CLOSED = 'Fly-Out Closed',
    FLY_OUT_VIEWED = 'Fly-Out Viewed',
    NAVIGATION_CLICKED = 'Navigation Clicked',
    DESIGN_POPUP_DISPLAYED = 'Design PopUp Displayed',
    PRODUCT_VIEWED = 'Product Viewed',
    IMAGE_UPLOADED = 'Image Uploaded',
    BUTTON_CLICKED = 'Button Clicked',
    QUANTITY_ENGAGEMENT = 'Gallery Quantity Selector Engagement',
    FAVORITES_SIGN_IN = 'Favorites Sign-In Callout Clicked',
    EXPERIMENT_CLICKED = 'Experiment Clicked',
    SORTING_DROPDOWN_OPENED = 'Sorting Dropdown Opened',
    SORTING_DROPDOWN_CLOSED = 'Sorting Dropdown Closed',
    SORTING_DROPDOWN_SELECTED = 'Sorting Dropdown Selected',
    GALLERY_FILTER_ENGAGEMENT = 'Gallery Filter Engagement',
    QV_OPTIONS_UNWINDING = 'QV Options Unwinding',
}

export enum ANALYTICS_CATEGORY {
    ALL = 'All',
    FAVORITES = 'Favorites',
    GALLERY = 'Gallery',
    FLY_OUT = 'Fly-Out',
    DESIGN_UPLOAD = 'Design - Upload',
}

export enum ANALYTICS_LABEL {
    SELECT_COLOR_SWATCH = 'SelectColorSwatch',
    SELECT_DESIGN = 'SelectDesign',
    FLY_OUT_EVENT = 'Gallery Fly-out',
    FLY_OUT_VIEWED = 'Fly Out Viewed',
    FAVORITE_ADDED = 'FavoriteDesignAdded',
    FAVORITE_REMOVED = 'FavoriteDesignRemoved',
    FAVORITES_CTA = 'Content:Favorites CTA',
    OPTIONS_DISPLAYED = 'Options Display',
    OPTIONS_VALIDATION = 'Options Validation',
    REVIEW_FLY_OUT_EVENT = 'Gallery Review Fly-out',
    ADD_TO_CART = 'Review:AddToCart',
    PHOTO_PREVIEW_FLY_OUT = 'Content Hub Gallery Photo Preview Fly-out',
    PHOTO_PREVIEW_CLEAR = 'Clear Photo Preview',
    PHOTO_PREVIEW_SUCCESS = 'Success',
    PHOTO_PREVIEW_ERROR = 'Error',
    QUANTITY_CHANGED = 'QuantityChanged',
    QUANTITY_SELECTOR_OPENED = 'QuantitySelectorOpened',
    PHOTO_PREVIEW_SIGN_IN = 'Sign-in',
    FAVORITES_SIGN_IN = 'Sign-in',
    CAROUSEL_PREVIOUS_SLIDE = 'Previous slide',
    CAROUSEL_NEXT_SLIDE = 'Next slide',
    CAROUSEL_VIEW_ALL = 'View All',
    CAROUSEL_LAST_SLIDE_VIEW_ALL = 'Carousel View All',
}

export enum ANALYTICS_EVENT_DETAILS {
    FAVORITE_ADDED = 'FavoriteDesignAdded',
    FAVORITE_REMOVED = 'FavoriteDesignRemoved',
    REVIEW = 'Review',
    SHIPPING_NOT_INCLUDED = 'Shipping not included',
    PREVIEW_WITH_PHOTO = 'Preview with your Photo',
    UPLOAD_PHOTO = 'Upload Photo',
    APPLY_PHOTO = 'Apply Photo',
    PRIVACY_CLICKED = 'Privacy & Cookie Policy',
    MERCH_MODULES = 'Merch Modules',
}

export enum WARNING_TYPE {
    Content = 'content',
}

// also used with scene type in database
export enum PREVIEW_TYPE {
    TILE = 'gallery',
    QUICKVIEW = 'quickview', // used for Quick View or Quick Studio
}

// Query Keys
export const MY_ACCOUNT_PAGE_SIDEBAR_CONTENT_QUERY_KEY = 'myAccountPageSidebarContent';
export const DEFAULT_QUANTITIES_QUERY_KEY = 'default_quantities';
export const ICONOGRAPHY_QUERY_KEY = 'iconography';
export const SEARCH_SUGGESTIONS = 'search_suggestions';

// Gallery Experience Type
export enum EXPERIENCE_TYPE {
    BASE = 'Base',
    GALLERY = 'Gallery',
    TLP = 'TLP',
    PRODUCT_PAGE = 'ProductPage',
}

export enum SCENE_SOUCE {
    GALLERY_SOURCED = 'GallerySourced',
    SCENE_LOOKUP_SOURCED = 'SceneLookupSourced'
}

export enum SCENE_TYPE {
    DYNAMIC = 'Dynamic',
    VORTEX_RASTER = 'VortexRaster'
}

export enum DESIGN_CREATION_TYPE {
    DYNAMIC = 'Dynamic',
    STATIC = 'Static'
}

export enum PERSONALIZATION_IMAGE {
    DEFAULT = 'default',
    HAPPY_HOLIDAYS = 'happyHolidays',
}

export enum TEMPLATE_PURPOSES {
    FULLBLEED = 'Fullbleed',
    LAYOUT = 'Layout',
    INSPIRATIONAL = 'Inspirational',
}

export enum ENVIRONMENT {
    PROD = 'prod',
    DEV = 'dev'
}

export enum PRODUCT_OPTIONS_PRODUCT_UPDATE {
    UPSELL = 'upsell',
    DOWNSELL = 'downsell',
    NO_CHANGE = 'nochange',
}

export const MAX_NUMBER_OF_INDEXABLE_PAGES = 5;
